import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _auth: AuthService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (this._auth.getData()) {
      const userData = JSON.parse(this._auth.getData());

      if (userData.accessToken) {
        return true;
      }
    }
    this.router.navigate(['/auth']);
    return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {

    if (this._auth.getData()) {
      const userData = JSON.parse(this._auth.getData());

      if (userData.accessToken) {
        return true;
      }
    }
    this.router.navigate(['/auth']);
    return false;
  }
}
