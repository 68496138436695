const api = "https://booking.recordit.fr/api";
export const environment = {
  production: true,
  AUTHENTICATION: api + "/auth/",
  AUTHMANAGEMENT: api + "/authManagement/",
  API_USER: api + "/users/",
  API_ACCOUNT: api + "/accounts/",
  API_ACCOUNT_USER: api + "/account-users/",
  API_TRANSACTION: api + "/transactions/",
  API_ROOM: api + "/rooms/",
  PUBLIC_API_ROOM: api + "/public/rooms/",
  API_BOOKING: api + "/bookings/",
  PUBLIC_API_BOOKING: api + "/public/bookings/",
};
