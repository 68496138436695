import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor() {
  }

  ngOnInit(): void {
    if (localStorage.getItem('language') == null) { localStorage.setItem('language', 'fr'); }
  }
}
