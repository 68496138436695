import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class EncryptionService {

    private secretKey = CryptoJS.enc.Utf8.parse('dvrjfh^%xce$d%f^*8oijhkasdhkjg67');
    private iv = CryptoJS.enc.Utf8.parse('rak^&4fghjjk%&Hi"');
    private options = {
        keySize: 128 / 8,
        iv: this.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };

    encryptData(plainData: any) {
        return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(plainData), this.secretKey, this.options).toString();
    }

    decryptData(cipherData: any) {
        return CryptoJS.AES.decrypt(cipherData, this.secretKey, this.options).toString(CryptoJS.enc.Utf8);
    }

}
