import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpParams } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, finalize, timeout, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationService } from 'src/app/core/notification.service';
import { EncryptionService } from 'src/app/core/encryption.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements HttpInterceptor {

  private permittedActions: string[] = ['POST', 'GET', 'PUT', 'DELETE', 'PATCH'];
  public role: String;
  constructor(
    private _router: Router,
    private _http: HttpClient,
    private _notification: NotificationService,
    private _encrypt: EncryptionService
  ) { }

  getBaseUrl() {
    const baseUrlArr = this._router.url.split('/');
    if (baseUrlArr.length >= 3) {
      return '/' + baseUrlArr[1] + '/' + baseUrlArr[2];
    }
    else if (baseUrlArr.length = 2) {
      return '/' + baseUrlArr[1];
    }
    else {
      return '/';
    }
  }
  getData() {
    return this.localStorageGetItem('admAuthData');
  }

  getAuthToken() {
    if (this.getData()) {
      return JSON.parse(this.getData()).accessToken;
    }
  }

  getMemberId() {
    if (this.getData()) {
      return JSON.parse(this.getData()).memberId;
    }
  }

  logout() {
    window.localStorage.clear();
    this._router.navigate(['/']);
  }

  localStorageSetItem(key: string, value: string) {
    window.localStorage.setItem(key, this._encrypt.encryptData(value));
  }

  localStorageGetItem(key: string) {
    if (window.localStorage.getItem(key)) {
      return this._encrypt.decryptData(window.localStorage.getItem(key));
    }
  }

  getHeader(){
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    headers = headers.append('Authorization', 'Bearer ' + this.getAuthToken());
  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.permittedActions.includes(request.method)) {
      return;
    }
    let headers = new HttpHeaders();

    if (this.getAuthToken()) {
      headers = headers.append('Authorization', 'Bearer ' + this.getAuthToken());
    }
    request = request.clone({ headers });
    return next.handle(request)
      .pipe(
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse && event.status === 200) {
            if (event.body && event.body.message) {
              this._notification.success(event.body.message);
            }
          }
        }),
        catchError((response) => {
          if (response.status === 400 || response.status === 422) {
            if (response.error != null) {
              let html = '';

              Object.keys(response.error.errors).forEach((key: string, index: number) => {
                html += response.error.errors[key].message + '</br>';
              });

              this._notification.warning(html);
            }
          }
          else if (response.status === 401) {
            this._notification.error('Unauthorized');
            this.logout();
          }
          else if (response.status === 404) {
            this._notification.info('Service is Temporarily unavailable.');
          }
          return throwError(response);
        }),
        timeout(30000)
      );
  }

}
